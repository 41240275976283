import * as React from "react"

import Inner from "~/components/Organisms/inner"
import ContentsTextBlock from "~/components/Molecules/contentsBlock"
import ContentsFooter from "~/components/Molecules/contentsFooter"
import Slider from "~/components/Molecules/works/slider"

import Data from "~/assets/data/stage4.json"

import WorksItems from "~/components/Molecules/works/worksItems"

import Layout from "~/components/Organisms/layout"
import Seo from "~/components/seo"



import {css} from "@emotion/react"


const KV = "/images/stage4/stage4-kv.jpg"

const stage4 = () => (
  <Layout>
    <Seo title="第３回公演「俺たちは、これで、うまく行っているほうじゃないかなぁ」" />
    <div css={kv}>
      <img src={KV} alt='第３回公演「俺たちは、これで、うまく行っているほうじゃないかなぁ」' />
    </div>
    <Inner>
      <ContentsTextBlock 
        title={Data.about.title}
        lead={Data.about.lead}
      />
      <Slider
        title='ギャラリー'
        imageNum='11'
        stageNum='4'
      />
      <ContentsTextBlock 
        title={Data.staff.title}
        lead={Data.staff.lead}
      />
      <WorksItems/>
    </Inner>
    <ContentsFooter />
  </Layout>
)

const kv = css({
  width:'100%'
})

export default stage4